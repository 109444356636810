import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Box,
  Container,
  createTheme,
  CssBaseline,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../context/AuthContext";

const theme = createTheme();

export default function Login() {
  const { login } = useContext(AuthContext);
  const [account, setAccount] = React.useState({ userName: "", password: "" });
  const [loading, setLoading] = useState(false);

  const handelAccount = (event) => {
    const accountCopy = { ...account };
    accountCopy[event.target.name] = event.target.value;
    setAccount(accountCopy);
  };

  const handelLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify(account);
    var requestOptions = {
      method: "POST",
      body: raw,
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === 200) {
          login({
            token: `Bearer ${result.token}`,
            isSignedIn: true,
          });
        } else {
          toast.error(result.message)
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          height: "80vh",
        }}
        component="main"
        maxWidth="xs"
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1 }}></Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handelLogin}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              onChange={handelAccount}
              label="Username"
              name="userName"
              autoComplete="userName"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              onChange={handelAccount}
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <LoadingButton
              variant="contained"
              onClick={handelLogin}
              loading={loading}
              loadingPosition="end"
              endIcon={<i className="fa-solid fa-right-to-bracket"></i>}
              sx={{ mt: 3, mb: 2 }}
              fullWidth
              type="submit"
            >
              Sign In
            </LoadingButton>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
