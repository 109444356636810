import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import OrdersStatus from "./pages/OrdersStatus";
import { socket, SocketContext } from "./context/socket";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Protected from "./utils/Protected";
import Login from "./pages/Login";
import { AuthStateProvider } from "./context/AuthContext";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Protected isProtected>
              <OrdersStatus />
            </Protected>
          }
        />
        <Route
          path="/login"
          element={
            <Protected>
              <Login />
            </Protected>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

function Providers() {
  return (
    <SocketContext.Provider value={socket}>
      <AuthStateProvider>
          <App />
          <ToastContainer />
      </AuthStateProvider>
    </SocketContext.Provider>
  );
}

export default Providers;
