import { createContext, useMemo } from "react";
import { useLocalStorage } from "../Hooks/useLocalStorage";

const userData = null;
export const AuthContext = createContext();

export const AuthStateProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", userData);

    const login = async (data) => {
        setUser(data);
      };
    
      const logout = () => {
        setUser(null);
      };
    
      const value = useMemo(
        () => ({
          user,
          login,
          logout
        }),
        [user]
      );
    
      return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
    
};
