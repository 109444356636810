import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

function Protected({ children, isProtected }) {
  const {user} = useContext(AuthContext);
  if (isProtected && !user?.isSignedIn) {
    return <Navigate to="/login" replace />;
  } else if (!isProtected && user?.isSignedIn) {
    return <Navigate to="/" replace />;
  } else return children;

}

export default Protected;
