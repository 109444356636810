import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';


const { fetch: originalFetch } = window;

window.fetch = async (...args) => {
	const [resource, config] = args;

	const response = await originalFetch(resource, config);
	// response interceptor
	// below function use as interceptor that check on the backend side that user is using latest build or not
	// making copy
	async function isJson(str) {
		try {
			const text = await str.clone().text();
			const data = JSON.parse(text);
		} catch (e) {
			return false;
		}
		return true;
	}
	let responseCopy = response?.clone();
	const checkJson = await isJson(responseCopy);
	// if the response is not JSON than send data directly
	if (!checkJson) return response;
	responseCopy = await responseCopy.json();
	// when user is using oldBuild and request the backend than below condition is executed
	if (responseCopy?.status === 401 && responseCopy?.isTokenError) {
		localStorage.clear();
		window.location.reload(false);
	} 
	return response;
};

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
