import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Toolbar,
  Typography,
  FormControlLabel,
  Popover
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useRef, useState } from "react";
// import OrderDialog from "../components/OrderDialog";
// import OrderSummary from "../components/OrderSummary";
import moment from "moment";
// import OrderTabs from "../components/OrderTabs";
import { SocketContext } from "../context/socket";
import {
  FAILED,
  FAILED_STATUS,
  getStatus,
  NO_STATUS,
  PENDING_STATUS,
  SUCCESS_STATUS,
} from "../utils/StatusManage";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";

const fakeData = {
  "InstanceStatuses": [
    {
      "AvailabilityZone": "eu-west-1a",
      "InstanceId": "i-037338d814d503541",
      "InstanceState": {
        "Code": 16,
        "Name": "running"
      },
      "InstanceStatus": {
        "Details": [
          {
            "Name": "reachability",
            "Status": "passed"
          }
        ],
        "Status": "ok"
      },
      "SystemStatus": {
        "Details": [
          {
            "Name": "reachability",
            "Status": "passed"
          }
        ],
        "Status": "ok"
      },
      "Name": "adap-integration-qa-01",
      "Description": "Orumnet New Integration Server"
    },
    {
      "AvailabilityZone": "eu-west-1a",
      "InstanceId": "i-03357ab393cbfa967",
      "InstanceState": {
        "Code": 16,
        "Name": "running"
      },
      "InstanceStatus": {
        "Details": [
          {
            "Name": "reachability",
            "Status": "passed"
          }
        ],
        "Status": "ok"
      },
      "SystemStatus": {
        "Details": [
          {
            "Name": "reachability",
            "Status": "passed"
          }
        ],
        "Status": "ok"
      },
      "Name": "qa-orumnet-3-0-instances",
      "Description": "Orumnet 3-0 Web Catalog Server"
    }
  ]
}

const FILTER_BY_DATE = -1;
const FILTER_BY_ORDER_ORIGIN = -2;

// const getStatusText = (params, variant = "p") => {
//   return (
//     <Typography
//       variant={variant}
//       style={
//         getStatus(params.row.status) === SUCCESS_STATUS
//           ? { color: "green", textAlign: "center" }
//           : getStatus(params.row.status) === FAILED_STATUS
//             ? { color: "red", textAlign: "center" }
//             : { color: "orange", textAlign: "center" }
//       }
//     >
//       {getStatus(params.row.status) === SUCCESS_STATUS
//         ? "Success"
//         : getStatus(params.row.status) === FAILED_STATUS
//           ? "Failed"
//           : "Pending"}
//     </Typography>
//   );
// };
const capitaliseFunction = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const getInstanceState = (params, variant = "p") => {
  return (
    <div style={{ padding: 10 }}>

      <Typography
        variant={variant}
        style={
          params.row.InstanceState.Name.toLowerCase() === 'running' ? { color: "green", textAlign: "center" } : { color: "red", textAlign: "center" }
        }
      >
        <div>
          {
            params.row.InstanceState.Name.toLowerCase() === 'running' ? <i className="fa-regular fa-check-circle fa-lg" style={{ marginRight: 10 }}></i> :<i class="fa-regular fa-circle-minus fa-lg" style={{ marginRight: 10 }}></i>
          }
        
          
          {capitaliseFunction(params.row.InstanceState.Name)}
        </div>
      </Typography>
    </div>
  )
}

const getSystemStatus = (params, variant = "p") => {
  return (
    <Typography
      variant={variant}
      style={
        params.row.SystemStatus.Details[0].Status.toLowerCase() === 'passed' ? { color: "green", textAlign: "center" } : { color: "red", textAlign: "center" }
      }
    >
      {capitaliseFunction(params.row.SystemStatus.Details[0].Status)}
    </Typography>
  );
};

const getInstanceStatus = (params, variant = "p") => {
  return (
    <Typography
      variant={variant}
      style={
        params.row.InstanceStatus.Details[0].Status.toLowerCase() === 'passed' ? { color: "green", textAlign: "center" } : { color: "red", textAlign: "center" }
      }
    >
      {capitaliseFunction(params.row.InstanceStatus.Details[0].Status)}
    </Typography>
  );
};

const pageSize = 5;

const OrdersStatus = () => {
  const [orderStatus, setOrderStatus] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const socket = useContext(SocketContext);
  const { logout } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // const [orderSummary, setOrderSummary] = useState(null);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = useState(10);
  const initialFilter = {
    orderId: "",
    axOrderId: "",
    showOrder: NO_STATUS,
    customerId: "",
    from: moment(),
    to: moment(),
    pastRecordsDay: 7,
    orderOrigin: -1,
    sort_order: 'desc',
    sort_field: 'order_created'
  };
  const [filter, setFilter] = useState({ ...initialFilter });
  const [defaultFilter, setDefaultFilter] = useState({ ...initialFilter });
  const refFilter = useRef(initialFilter);
  const refAutoRefresh = useRef(true)
  const [autoRefresh, setAutoRefresh] = useState(true)

  useEffect(() => {
    try {
      fetchOrders();
    } catch (e) {
      console.log("err", e);
    }
  }, []);

  const fetchOrders = async (
    draw = 1,
    limit = pageSize,
    filtersValues = { ...defaultFilter },
    autoUpdate
  ) => {
    // setOrderStatus(fakeData.InstanceStatuses.map((item) => ({ ...item, Name: `${item.Description} (${item.Name})` })));
    draw = autoUpdate ? +sessionStorage.getItem("page") + 1 : draw;
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(`${process.env.REACT_APP_API_URL}/list`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.status === 200)
          setOrderStatus(result.data.InstanceStatuses.map((item) => ({ ...item,  })));
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };



  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickConfirmation = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseConfirmation = () => {
    setAnchorEl(null);
  };

  const rebootServer = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      JSON.parse(localStorage.getItem("user")).token
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "command": "reboot",
      "instance_id": [
        id
      ]
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_API_URL}/command`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        toast.success("Server is rebooted Successfully!!", {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        // toast("Services may take few seconds to get up & running after reboot", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        // });
      })
      .catch(error => console.log('error', error));
  }

  const gridColumns = [
    {
      field: "Server",
      headerName: "Server",
      flex: 0.16,
      sortable: false,
      minWidth: 400,
      renderCell: (params)=>(
        <>
          <div title={params.row.Meta}>
            <b>{params.row.Description}</b>
            <br/>
            {params.row.Meta}
          </div>
        </>
      )
    },
    {
      field: "Name",
      headerName: "Name",
      flex: 0.16,
      sortable: false

    },

    {
      field: "InstanceState",
      headerName: "Instance State",
      flex: 0.16,
      sortable: false,
      renderCell: getInstanceState
    },
    {
      field: "SystemStatus",
      headerName: "System Status Check",
      flex: 0.16,
      sortable: false,
      renderCell: getSystemStatus
    },
    {
      field: "InstanceStatus",
      headerName: "Instance Status Check",
      flex: 0.16,
      sortable: false,
      renderCell: getInstanceStatus
    },
    {
      field: "button",
      headerName: "Action",
      renderCell: (params) => {
        const open = Boolean(anchorEl);
        return (
          <>
            {/* <IconButton size="large" color="info" title="Refresh Server Status" onClick={() => fetchOrders()}>
            <Icon>
              <i className="fa-solid fa-md fa-refresh"></i>
            </Icon>
          </IconButton> */}

            <IconButton size="large" color="error" title="Reboot Server" onClick={handleClickConfirmation}>
              <Icon>
                <i className="fa-solid fa-md fa-arrow-rotate-left"></i>
              </Icon>
            </IconButton>
            <Popover
              id={params.id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleCloseConfirmation}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              PaperProps={{
                style: { width: 220, padding: 15, marginTop: 10 },
              }}
              elevation={20}
            >
              <center>
                <Typography>
                  Confirm reboot the server?
                </Typography>
                <div style={{marginTop: 10}}>
                <span style={{fontSize: 10}}>Note: Services may take few seconds to <br/> get up & running after reboot</span>
                </div>
                <br/>
                <Button
                  variant="contained"
                  onClick={(e) => { rebootServer(params.row.InstanceId); handleCloseConfirmation(e) }}
                  // onClick={handleClickConfirmation}
                  size='small'
                >
                  Confirm
                </Button>
              </center>
            </Popover>
          </>
        )
      },
      sortable: false,
      flex: 0.16,
    },
  ];


  const [rowCountState, setRowCountState] = useState(pageInfo?.total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      pageInfo?.total !== undefined ? pageInfo?.total : prevRowCountState
    );
  }, [pageInfo?.total, setRowCountState]);


  return (
    <Grid container justifyContent="center">
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        <AppBar variant="elevation">
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Örumnet Server Status
            </Typography>
            <Button onClick={logout} color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Grid item xs={10} style={{ padding: 45, fontWeight: "bold" }}>


        <Grid
          container
          style={{ paddingTop: 25, fontWeight: "bold" }}
          justifyContent="right"
        >
          <Grid item alignSelf="right">
            <Button onClick={() => fetchOrders()} style={{ margin: '5px' }}>Refresh</Button>
          </Grid>
        </Grid>

        <DataGrid
          // pageSize={pageSize}
          pagination={false}
          disableColumnMenu
          disableSelectionOnClick
          autoHeight
          rows={orderStatus}
          columns={gridColumns}
          rowCount={rowCountState}
          loading={loading}
          page={page}
          paginationMode="server"
          getRowId={(row) => row.InstanceId}
          autoPageSize={false}
          hideFooter={true}
        />

      </Grid>
    </Grid>
  );
};

export default OrdersStatus;
