export const SUCCESS_STATUS = 1;
export const PENDING_STATUS = 2;
export const FAILED_STATUS = 3;
export const NO_STATUS = 4;
export const PENDING = [1, 2, 3, 5, 6, 7, 8];
export const SUCCESS = [10, 12];
export const FAILED = [4, 9, 13];

export const getStatus = (status) => {
  // const PENDING = [1, 2, 3, 5, 6, 7, 8];
  // const SUCCESS = [10, 12];
  // const FAILED = [4, 9, 13];

  if (SUCCESS.includes(status)) return SUCCESS_STATUS;
  if (FAILED.includes(status)) return FAILED_STATUS;
  return PENDING_STATUS;
};

export const filterStatus = (orders, statusNumber) => {
  return orders.filter((item) => {
    if (
      statusNumber === SUCCESS_STATUS &&
      getStatus(item.status) === SUCCESS_STATUS
    )
      return true;
    if (
      statusNumber === FAILED_STATUS &&
      getStatus(item.status) === FAILED_STATUS
    )
      return true;
    if (
      statusNumber === PENDING_STATUS &&
      getStatus(item.status) === PENDING_STATUS
    )
      return true;
    if (statusNumber === NO_STATUS) return true
    return false;
  });
};
